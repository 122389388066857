import dateFormatter from "utils/date_formatter";
import axios from "axios";
import transport from "./utils/transport";
import caseConverter from "utils/case_converter";

const PATH_PREFIX = `/api/v1/meta/${process.env.REACT_APP_CHANNEL_CODE}`;
const GENERAL_API_KEY = process.env.REACT_APP_GENERAL_KEY;

const HOTEL_POINT_URL = process.env.REACT_APP_HOTEL_POINT_URL;
export default {
  getPropertyInfo: (propertyChannelId) => {
    return transport.get(`${PATH_PREFIX}/${propertyChannelId}/property_info`);
  },

  getThemeData: async (channelId) => {
    const url = `${HOTEL_POINT_URL}/api/pms/booking-engine-ui-settings`;
    try {
      const response = await axios({
        method: "post",
        url: url,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          channel_id: channelId,
        },
        timeout: 4000, // Timeout in milliseconds
      });

      const data = response.data;
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  getClosedDates: (propertyChannelId) => {
    return transport.get(`${PATH_PREFIX}/${propertyChannelId}/closed_dates`);
  },

  getRoomsInfo: (propertyChannelId, queryParams) => {
    const { checkinDate, checkoutDate, ...restParams } = queryParams;

    let formattedQueryParams = {
      ...restParams,

      exactMatch: true,
    };

    const formattedDates = {
      checkinDate: dateFormatter.toApi(checkinDate),
      checkoutDate: dateFormatter.toApi(checkoutDate),
    };

    if (formattedDates.checkinDate && formattedDates.checkoutDate) {
      formattedQueryParams = { ...formattedQueryParams, ...formattedDates };
    }

    formattedQueryParams = {
      ...formattedQueryParams,
      withVirtualRatePlans: true,
    };

    return transport.get(`${PATH_PREFIX}/${propertyChannelId}/rooms`, formattedQueryParams);
  },

  getBestOffer: (propertyChannelId, queryParams) => {
    const formattedQueryParams = {
      currency: queryParams.currency,
    };

    if (queryParams.checkinDate && queryParams.checkoutDate) {
      formattedQueryParams.checkinDate = dateFormatter.toApi(queryParams.checkinDate);
      formattedQueryParams.checkoutDate = dateFormatter.toApi(queryParams.checkoutDate);
    }

    return transport.get(`${PATH_PREFIX}/${propertyChannelId}/best_offer`, formattedQueryParams);
  },

  createBooking: (propertyChannelId, booking) => {
    return transport.post(`${PATH_PREFIX}/${propertyChannelId}/push_booking`, { booking });
  },

  getCaptureFormUrl: () => {
    return transport.get(`${PATH_PREFIX}/card_capture_form_url`);
  },

  getPropertiesList: (queryParams, filter = {}) => {
    const { nw: _nw, se: _se, sw: _sw, ne: _ne, ...paramsWithoutFilter } = queryParams;
    const GROUP_ID = process.env.REACT_APP_GROUP_ID;

    if (GROUP_ID) {
      filter["group_id"] = GROUP_ID;
    }

    return transport.get(`${PATH_PREFIX}/property_list`, {
      is_available: true,
      filter,
      ...paramsWithoutFilter,
    });
  },

  fetchBooking: async (confirmation_no) => {
    // temporray check for only this booking will remove this booking when completed in future
    const encodedConfirmationNo =
      confirmation_no?.toUpperCase() === "1A8E909DAB"
        ? encodeURIComponent("1A8E909DAB ")
        : confirmation_no;
    const url = `${process.env.REACT_APP_API_URL}/api/v1/bookings?filter[ota_reservation_code]=${encodedConfirmationNo}`;
    try {
      const response = await fetch(url, {
        headers: {
          "user-api-key": GENERAL_API_KEY,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  getAdditionService: async (id) => {
    const url = `${HOTEL_POINT_URL}/api/pms/additional-services-list`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          room_type_id: id,
        }),
      });
      const data = await response.json();

      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getServiceAvailiblity: async (service_id, date) => {
    const url = `${HOTEL_POINT_URL}/api/pms/additional-service-availability`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: service_id,
          date: date,
        }),
      });
      const data = await response.json();

      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  becomeMember: async (values, channelId) => {
    const url = `${HOTEL_POINT_URL}/api/pms/guest-membership-request`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: values.full_name,
          phone: values.phone,
          country: values.country,
          zip: values.zip,
          channel_id: channelId,
          email: values.email,
        }),
      });
      const data = await response.json();

      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getTravelAgent: async (values, channelId) => {
    const url = `${HOTEL_POINT_URL}/api/pms/travel-agent-request`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: values.Email,
          name: values.Name,
          phone: values.phoneNumber,
          country: values.country,
          activity: values.Activity,
          vat_no: values.vatNo,
          tax_office: values.taxOffice,
          address: values.Address,
          channel_id: channelId,
          head_quarter: values.headQuarter,
          zip: values.postalCode,
        }),
      });
      const data = await response.json();

      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getDiscount: async (email, phone, channel_id) => {
    const url = `${HOTEL_POINT_URL}/api/pms/guest-discount`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          channel_id: channel_id,
          phone: phone,
        }),
      });
      const data = await response.json();

      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  verifyMember: async (userId) => {
    const url = `${HOTEL_POINT_URL}/api/pms/get-guest-verification-link`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: userId,
        }),
      });
      const data = await response.json();

      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  handleNexiPayment: async (reqBody) => {
    const url = `${HOTEL_POINT_URL}/api/nexi/generate-hosted-page-link`;

    const formBody = caseConverter.convertToSnakeCase(reqBody);

    console.log("formBody2222", formBody);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formBody),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  getTravelAgentDiscount: async (email, phone, channel_id) => {
    const url = `${HOTEL_POINT_URL}/api/pms/travel-agent-discount`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          channel_id: channel_id,
          phone: phone,
        }),
      });
      const data = await response.json();

      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getUserBookings: async (bookingParams) => {
    const url = `${HOTEL_POINT_URL}/api/pms/get-agent-bookings`;

    try {
      const response = await axios.post(url, bookingParams, {
        headers: {
          "Content-Type": "multipart-formdata",
        },
      });

      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getProperty: async (channel_id) => {
    const url = `${HOTEL_POINT_URL}/api/pms/get-property`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          channel_id: channel_id,
        }),
      });
      const data = await response.json();

      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  getPromotion: async (channel_id) => {
    const url = `${HOTEL_POINT_URL}/api/pms/get-promotions`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          channel_id: channel_id,
        }),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },
  getEvents: async (channel_id) => {
    const url = `${HOTEL_POINT_URL}/api/pms/get-events`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          channel_id: channel_id,
        }),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },
  getSisterProperties: async (channel_id) => {
    const url = `${HOTEL_POINT_URL}/api/pms/get-sister-hotels`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          channel_id: channel_id,
        }),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },
  getNotifications: async (channel_id) => {
    const url = `${HOTEL_POINT_URL}/api/pms/get-notifications`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          channel_id: channel_id,
        }),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },
};
